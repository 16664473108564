#contact-center {
}

#contact-frame {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: calc(40rem / 16);
}

#contact-details {
  display: flex;
  flex-direction: column;
  height: fit-content;
  color: rgb(255, 240, 208);
  background-color: rgba(255, 240, 208, 0.08);
  padding: 30px;
  box-sizing: border-box;
  border-radius: calc(4rem / 16);
  row-gap: calc(20rem / 16);
}

#contact-details > h1 {
  margin: 0px;
}

#contact-details > h2 {
  margin: 0px;
  font-weight: normal;
}

#contact-form {
  display: flex;
  flex-direction: column;
  row-gap: calc(16rem / 16);
  height: fit-content;
  color: rgb(255, 240, 208);
  background-color: rgba(255, 240, 208, 0.08);
  padding: 30px;
  box-sizing: border-box;
  border-radius: calc(4rem / 16);
}

#contact-form > div > label {
  font-size: calc(20rem / 16);
}

#contact-form > .contact-oneline {
  display: flex;
  flex-direction: column;
  row-gap: calc(10rem / 16);
}

#contact-form > .contact-oneline > input {
  background-color: rgba(255, 240, 208, 0.14);
  color: rgb(255, 240, 208);
  padding: calc(10rem / 16);
  font-size: calc(16rem / 16);
  border-radius: calc(4rem / 16);
  border: none;
  font-family: poppins;
}

#contact-form > .contact-multiline {
  display: flex;
  flex-direction: column;
  row-gap: calc(10rem / 16);
}

#contact-form > .contact-multiline > textarea {
  resize: vertical;
  background-color: rgba(255, 240, 208, 0.14);
  color: rgb(255, 240, 208);
  height: calc(200rem / 16);
  padding: calc(10rem / 16);
  font-size: calc(16rem / 16);
  border-radius: calc(4rem / 16);
  border: none;
  font-family: poppins;
}

#contact-form > #contact-submit {
  display: flex;
  column-gap: calc(16rem / 16);
  align-items: center;
}

#contact-form > #contact-submit > input[type="submit"] {
  font-size: calc(24rem / 16);
  height: fit-content;
  width: fit-content;
  padding: calc(14rem / 16) calc(32rem / 16);
  background-color: rgba(255, 127, 80, 0.88);
  color: rgb(255, 240, 208);
  border-radius: calc(4rem / 16);
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  font-family: poppins;
}

#contact-form > #contact-submit > input[type="submit"]:hover {
  background-color: rgba(209, 104, 65, 0.88);
}

#contact-form > #contact-submit > h3 {
  margin: 0px;
  font-size: calc(20rem / 16);
}

@media screen and (max-width: 1024px) {
  #contact-frame {
    grid-template-columns: 100%;
    grid-template-rows: auto 1fr;
    row-gap: calc(40rem / 16);
  }

  #contact-details {
    width: 100%;
  }
}
