#home-center {
  row-gap: 30px;
}

#home-hero {
  padding: 0px calc(24rem / 16);
  height: calc(480rem / 16);
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  align-items: flex-end;
  justify-content: center;
  background-color: rgba(255, 240, 208, 0.08);
  background-image: url("../../public/hero-image.png");
  background-size: cover;
  background-position: right;
  text-align: center;
  row-gap: calc(30rem / 16);
  border-radius: calc(4rem / 16);
}

#home-hero > div {
  margin: calc(118rem / 16);
  color: rgb(255, 240, 208);
  display: flex;
  flex-direction: column;
  align-items: center;
}

#home-hero > div > div > h1 {
  font-size: calc(42rem / 16);
}

#home-hero > div > div > h2 {
  font-size: calc(28rem / 16);
  font-weight: normal;
}

#home-hero-button {
  text-decoration: none;
}

#home-hero-button > h1 {
  background-color: rgba(255, 127, 80, 0.88);
  width: fit-content;
  padding: calc(14rem / 16) calc(32rem / 16);
  border-radius: calc(4rem / 16);
  font-size: calc(24rem / 16);
  color: rgb(255, 240, 208);
  transition: all 0.2s ease-in-out;
  font-weight: normal;
}

#home-hero-button > h1:hover {
  background-color: rgba(209, 104, 65, 0.88);
  transform: scale(1.02);
}

#home-testimonies {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: calc(24rem / 16);
  padding: 0px calc(24rem / 16);
}

.home-testimony {
  display: flex;
  flex-direction: column;
  text-align: center;
  row-gap: calc(24rem / 16);
  justify-content: space-between;
}

.home-testimony > h1,
p {
  margin: 0px;
}

.home-testimony > h1 {
  font-size: calc(24rem / 16);
  color: rgb(255, 240, 208);
}

.home-testimony > p {
  font-style: italic;
  font-size: calc(18rem / 16);
  color: rgb(255, 240, 208);
}

@media screen and (max-width: calc(800rem/16)) {
  #home-hero > div {
    margin: 0px;
  }

  #home-testimonies {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    row-gap: calc(24rem / 16);
  }

  .home-testimony {
    justify-content: center;
  }
}
