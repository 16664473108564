#estimate-center {
}

#estimate-frame {
  display: grid;
  grid-template-columns: 1fr 60%;
  column-gap: calc(40rem / 16);
}

#estimate-details {
  display: flex;
  flex-direction: column;
  height: fit-content;
  color: rgb(255, 240, 208);
  background-color: rgba(255, 240, 208, 0.08);
  padding: 30px;
  box-sizing: border-box;
  border-radius: calc(4rem / 16);
  row-gap: calc(20rem / 16);
}

#estimate-details > h1 {
  margin: 0px;
}

#estimate-details > h2,
h3 {
  margin: 0px;
  font-weight: normal;
}

#estimate-contactus {
  text-decoration: none;
  color: coral;
  transition: all 0.2s ease-in-out;
}

#estimate-contactus:hover {
  color: rgb(209, 104, 65);
}

#estimate-viewer {
  display: flex;
  flex-direction: column;
  color: rgb(255, 240, 208);
  row-gap: calc(10rem / 16);
  position: relative;
  height: 800px;
  height: fit-content;
}

#estimate-viewer > div {
  height: 76vh;
  min-height: calc(600em / 16);
  max-height: calc(800em / 16);
  background-color: rgba(25, 25, 41, 0.4);
  border-radius: calc(4rem / 16);
  position: relative;
}

#estimate-viewer > div > #estimate-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 240, 208, 0.14);
  border-radius: calc(4rem / 16);
}

#estimate-viewer > form {
  display: flex;
  flex-direction: column;
  row-gap: calc(16rem / 16);
  font-size: calc(20rem / 16);
  position: absolute;
  bottom: 20px;
  left: 20px;
  pointer-events: none;
  width: 76%;
}

#estimate-viewer > form > input[type="file"] {
  font-size: calc(16rem / 16);
  font-family: poppins;
}

#estimate-viewer > form > input[type="file"]::file-selector-button {
  font-size: calc(16rem / 16);
  margin-right: 8px;
  width: fit-content;
  padding: calc(7rem / 16) calc(16rem / 16);
  background-color: rgb(255, 240, 208);
  color: coral;
  border-radius: calc(4rem / 16);
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  pointer-events: all;
  font-family: poppins;
}

#estimate-viewer > form > input[type="file"]::file-selector-button:hover {
  background-color: rgb(228, 214, 186);
}

#estimate-viewer > form > div {
  display: flex;
  column-gap: calc(12rem / 16);
  align-items: center;
}

#estimate-viewer > form > div > h3 {
  margin: 0px;
  font-size: calc(24rem / 16);
  font-weight: normal;
}

#estimate-viewer > form > div > h3 > span {
  font-weight: bold;
}

#estimate-viewer > form > div > input[type="submit"] {
  font-size: calc(24rem / 16);
  height: fit-content;
  width: fit-content;
  padding: calc(14rem / 16) calc(32rem / 16);
  background-color: rgba(255, 127, 80, 0.88);
  color: rgb(255, 240, 208);
  border-radius: calc(4rem / 16);
  border: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  pointer-events: all;
  font-family: poppins;
  /* font-weight: bold; */
}

#estimate-viewer > form > div > input[type="submit"]:hover {
  background-color: rgba(209, 104, 65, 0.88);
}

#estimate-viewer > form > div > input[type="submit"]:disabled {
  background-color: rgb(117, 117, 117);
  cursor: default;
}

@media screen and (max-width: 1024px) {
  #estimate-frame {
    grid-template-columns: 100%;
    grid-template-rows: auto 1fr;
    row-gap: calc(40rem / 16);
  }

  #estimate-details {
    width: 100%;
  }
}
