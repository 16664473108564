#footer-grid {
  display: grid;
  grid-template-columns: 1fr minmax(calc(500rem / 16), calc(1500rem / 16)) 1fr;
  background-color: rgb(18, 18, 29);
  height: calc(100rem / 16);
  color: rgb(255, 240, 208);
}

#footer-center {
  grid-column-start: 2;
  padding: 0px calc(24rem / 16);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(18, 18, 29);
}
